import React from "react"
import { graphql } from 'gatsby'
import {Wrap} from "../components/basics"
import Layout from '../components/layout'




function goBack() {
  window.history.go(-1);
}





export default ({ data, pageContext }) => {

  const post = data.markdownRemark;



  return (
    <>



    <Layout>





      <Wrap>

              <button onClick={goBack}>←</button>

            <hr style={{marginTop: "2vw", marginBottom:"2vw"}}></hr>


            <h1>
              {post.frontmatter.title}
            </h1>

            <div className = "MarkdownPost">

            <div dangerouslySetInnerHTML={{ __html: post.html }} />

            </div>

            <button onClick={goBack}>←</button>

      </Wrap>




    </Layout>

    </>

  )
}





export const query = graphql`
  query BlogPostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
  `
